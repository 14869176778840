<template>
  <v-row no-gutters class="my-6">
    <v-icon v-if="icon" class="mr-2 d-print-none"> {{ icon }}</v-icon>
    <h1>
      {{ title }}
    </h1>
    <v-spacer />
    <v-btn v-if="backButton" class="d-print-none" @click="$router.back()">
      <v-icon>mdi-arrow-left</v-icon>
      <span class="ml-2">{{ $t('global.back') }}</span>
    </v-btn>
    <v-btn v-if="printButton" class="ml-2 d-print-none" data-cy="print-button" @click="print"
      ><v-icon>mdi-printer</v-icon></v-btn
    >
    <slot name="actions"></slot>
  </v-row>
</template>

<script>
export default {
  props: {
    title: { type: String, required: true },
    icon: { type: String, required: false, default: null },
    backButton: {
      type: Boolean,
      required: false,
      default: false
    },
    printButton: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  methods: {
    print() {
      window.print()
    }
  }
}
</script>

<style scoped></style>
